<template>
    <div>
        <Modal :options="{ width: '45vw' }">
            <form @submit.prevent="onSubmit">
                <div class="title">{{ !ctl_catalogo.id ? 'Crear catálogo' : 'Actualizar catálogo' }}</div>
                <div class="body">
                    <div class="row justify-content-center">
                        <div class="col-md-12 my-2">
                            <label for="nombre">Nombre</label>
                            <input v-model="ctl_catalogo.nombre" type="text" name="nombre" id="nombre"
                                class="form-control">
                        </div>

                        <div class="col-md-12 my-2">
                            <label for="codigo">Codigo</label>
                            <input v-model="ctl_catalogo.codigo" type="text" name="codigo" id="codigo"
                                class="form-control">
                        </div>

                        <div class="col-md-12 my-2">
                            <label for="estatus">Estatus</label>
                            <select v-model="ctl_catalogo.estatus" name="estatus" id="estatus" class="form-control">
                                <option value="1">Activo</option>
                                <option value="0">Inactivo</option>
                            </select>
                        </div>

                        <div class="col-md-12 my-2">
                            <label for="descripcion">Descripción</label>
                            <textarea v-model="ctl_catalogo.descripcion" name="descripcion" id="descripcion" cols="30"
                                rows="2" class="form-control" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 my-3 text-left" v-if="!catalogos_disable.includes(ctl_catalogo.codigo)">
                            <button class="btn btn-info" type="button" @click="agragar_opcion_form = true">
                                <i class="fa-solid fa-plus"></i>
                                Agregar opción
                            </button>
                        </div>
                    </div>

                    <div class="row shadow p-2">
                        <div class="col-sm-12 catalogo_opciones">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Valor</th>
                                        <th>Estatus</th>
                                        <th v-if="!catalogos_disable.includes(ctl_catalogo.codigo)">Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="agragar_opcion_form">
                                        <td>
                                            <input v-model="ctl_opcion.nombre" type="text" name="nombre" id="nombre"
                                                class="form-control">
                                        </td>
                                        <td>
                                            <input v-model="ctl_opcion.valor" type="text" name="valor" id="valor"
                                                class="form-control">
                                        </td>
                                        <td>
                                            <select v-model="ctl_opcion.estatus" name="estatus" id="estatus"
                                                class="form-control">
                                                <option value="1">Activo</option>
                                                <option value="0">Inactivo</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="btn btn-primary btn-sm mr-2" type="button"
                                                @click="agregar_opcion">
                                                <i
                                                    :class="{ 'fa-solid fa-check': !ctl_opcion.index, 'fas fa-sync': ctl_opcion.index }"></i>
                                                {{ !ctl_opcion.index ? 'Agregar' : 'Actualizar' }}
                                            </button>

                                            <button class="btn btn-danger btn-sm" type="button"
                                                @click="cancelar_form_opcion">
                                                <i class="fa-solid fa-trash"></i>
                                                Cancelar
                                            </button>

                                        </td>
                                    </tr>
                                    <tr v-for="(opcion, index) in ctl_catalogo.opciones">
                                        <td>{{ opcion.nombre }}</td>
                                        <td>{{ opcion.valor }}</td>
                                        <td>{{ opcion.estatus == 1 ? 'Activo' : 'Inactivo' }}</td>
                                        <td v-if="!catalogos_disable.includes(ctl_catalogo.codigo)">
                                            <button class="btn btn-primary mr-2" type="button"
                                                @click="editar_opcion(opcion, index)">
                                                <i class="fa-regular fa-pen-to-square"></i>
                                            </button>
                                            <button class="btn btn-danger mr-2" type="button"
                                                @click="quitar_opcion(index)">
                                                <i class="fa-solid fa-trash-can"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <div class="row">
                        <div class="col-sm-12 text-right">
                            <button class="btn btn-primary mr-2" type="button" @click="onSubmit">
                              <i class="fa-solid fa-floppy-disk"></i>
                              Guardar
                            </button>
                            <button class="btn btn-danger" type="button" @click="$emit('close')">
                              <i class="fa-solid fa-xmark"></i>
                              Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal';

import apiCondusef from '@/apps/condusef/api/catalogos';

const catalogos_disable = ['niveles_de_atencion', 'medios_de_recepcion', 'lista_de_productos', 'lista_de_causas_para_institucion_financiera'];

export default {
    components: {
        Modal
    },
    props: {
        catalogo: {
            type: Object
        }
    },
    data() {
        return {
            ctl_catalogo: {
                nombre: null,
                codigo: null,
                descripcion: null,
                estatus: 1,
                opciones: []
            },
            ctl_opcion: {
                nombre: null,
                valor: null,
                estatus: 1
            },
            agragar_opcion_form: false,
            catalogos_disable
        }
    },
    mounted() {
        if (this.catalogo)
            this.ctl_catalogo = this.$helper.clone(this.catalogo);

        if (this.ctl_catalogo.id)
            this.obtener_catalogo();
    },
    methods: {
        async obtener_catalogo() {
            try {
                this.ctl_catalogo = (await apiCondusef.obtener_catalogo(this.ctl_catalogo.id)).data;
            } catch (e) {
                this.$log.error(e);
                this.$helper.showAxiosError(e, 'Error');
            }
        },
        agregar_opcion() {
            if (!this.ctl_opcion.index)
                this.ctl_catalogo.opciones.push(this.ctl_opcion);
            else {
                let opciones = []
                for (let i = 0; i < this.ctl_catalogo.opciones.length; i++) {
                    if (i == this.ctl_opcion.index)
                        opciones.push(this.ctl_opcion);
                    else
                        opciones.push(this.ctl_catalogo.opciones[i]);
                }

                this.ctl_catalogo.opciones = opciones;
            }
            this.cancelar_form_opcion();
        },
        editar_opcion(opcion, index) {
            this.ctl_opcion = this.$helper.clone(opcion);
            this.ctl_opcion.index = index;
            this.agragar_opcion_form = true;
        },
        quitar_opcion(index) {
            let opciones = [];

            for (let i = 0; i < this.ctl_catalogo.opciones.length; i++) {
                if (i != index)
                    opciones.push(this.ctl_catalogo.opciones[i]);
            }

            this.ctl_catalogo.opciones = opciones;
        },
        cancelar_form_opcion() {
            this.ctl_opcion = {
                nombre: null,
                valor: null,
                estatus: 1
            }
            this.agragar_opcion_form = false;
        },
        async onSubmit() {
            try {
                let response = null;
                if (!this.ctl_catalogo.id) {
                    response = (await apiCondusef.crear_catalogo(this.ctl_catalogo)).data
                    this.$helper.showMessage('Crear Catálogo', 'Catálogo guardado exitosamente.', 'success', 'alert')
                } else {
                    response = (await apiCondusef.actualizar_catalogo(this.ctl_catalogo.id, ctl_catalogo)).data
                    this.$helper.showMessage('Editar Catálogo', 'Catálogo actualizado exitosamente.', 'info', 'alert')
                }
                this.$log.info('response', response);
                this.$emit('update');
            } catch (error) {
                this.$log.error('error', error);
                this.$helper.showAxiosError(error, 'Error');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.catalogo_opciones {
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;

    table {
        width: 100%;

        thead {
            tr {
                background-color: hsl(0, 0%, 30%);

                th {
                    padding: 10px 40px;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 10px 15px;
                }
            }

            tr:nth-child(2n+0) {
                background-color: hsl(0, 0%, 90%);
            }
        }
    }
}
</style>